exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-404-tr-js": () => import("./../../../src/pages/404.tr.js" /* webpackChunkName: "component---src-pages-404-tr-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-tr-js": () => import("./../../../src/pages/about.tr.js" /* webpackChunkName: "component---src-pages-about-tr-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-careers-tr-js": () => import("./../../../src/pages/careers.tr.js" /* webpackChunkName: "component---src-pages-careers-tr-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-tr-js": () => import("./../../../src/pages/contact.tr.js" /* webpackChunkName: "component---src-pages-contact-tr-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-cookie-policy-tr-js": () => import("./../../../src/pages/cookie-policy.tr.js" /* webpackChunkName: "component---src-pages-cookie-policy-tr-js" */),
  "component---src-pages-d-2-c-js": () => import("./../../../src/pages/d2c.js" /* webpackChunkName: "component---src-pages-d-2-c-js" */),
  "component---src-pages-d-2-c-tr-js": () => import("./../../../src/pages/d2c.tr.js" /* webpackChunkName: "component---src-pages-d-2-c-tr-js" */),
  "component---src-pages-digital-consultancy-js": () => import("./../../../src/pages/digital-consultancy.js" /* webpackChunkName: "component---src-pages-digital-consultancy-js" */),
  "component---src-pages-digital-consultancy-tr-js": () => import("./../../../src/pages/digital-consultancy.tr.js" /* webpackChunkName: "component---src-pages-digital-consultancy-tr-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-tr-js": () => import("./../../../src/pages/index.tr.js" /* webpackChunkName: "component---src-pages-index-tr-js" */),
  "component---src-pages-lab-js": () => import("./../../../src/pages/lab.js" /* webpackChunkName: "component---src-pages-lab-js" */),
  "component---src-pages-meet-tio-js": () => import("./../../../src/pages/meet-tio.js" /* webpackChunkName: "component---src-pages-meet-tio-js" */),
  "component---src-pages-meet-tio-tr-js": () => import("./../../../src/pages/meet-tio.tr.js" /* webpackChunkName: "component---src-pages-meet-tio-tr-js" */),
  "component---src-pages-our-products-js": () => import("./../../../src/pages/our-products.js" /* webpackChunkName: "component---src-pages-our-products-js" */),
  "component---src-pages-our-products-tr-js": () => import("./../../../src/pages/our-products.tr.js" /* webpackChunkName: "component---src-pages-our-products-tr-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-privacy-policy-tr-js": () => import("./../../../src/pages/privacy-policy.tr.js" /* webpackChunkName: "component---src-pages-privacy-policy-tr-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-resources-tr-js": () => import("./../../../src/pages/resources.tr.js" /* webpackChunkName: "component---src-pages-resources-tr-js" */),
  "component---src-pages-space-book-a-spot-js": () => import("./../../../src/pages/space/book-a-spot.js" /* webpackChunkName: "component---src-pages-space-book-a-spot-js" */),
  "component---src-pages-space-js": () => import("./../../../src/pages/space.js" /* webpackChunkName: "component---src-pages-space-js" */),
  "component---src-pages-studio-js": () => import("./../../../src/pages/studio.js" /* webpackChunkName: "component---src-pages-studio-js" */),
  "component---src-templates-blog-author-post-archive-js": () => import("./../../../src/templates/blog-author-post-archive.js" /* webpackChunkName: "component---src-templates-blog-author-post-archive-js" */),
  "component---src-templates-blog-author-post-archive-tr-js": () => import("./../../../src/templates/blog-author-post-archive.tr.js" /* webpackChunkName: "component---src-templates-blog-author-post-archive-tr-js" */),
  "component---src-templates-blog-category-post-archive-js": () => import("./../../../src/templates/blog-category-post-archive.js" /* webpackChunkName: "component---src-templates-blog-category-post-archive-js" */),
  "component---src-templates-blog-category-post-archive-tr-js": () => import("./../../../src/templates/blog-category-post-archive.tr.js" /* webpackChunkName: "component---src-templates-blog-category-post-archive-tr-js" */),
  "component---src-templates-blog-post-archive-js": () => import("./../../../src/templates/blog-post-archive.js" /* webpackChunkName: "component---src-templates-blog-post-archive-js" */),
  "component---src-templates-blog-post-archive-tr-js": () => import("./../../../src/templates/blog-post-archive.tr.js" /* webpackChunkName: "component---src-templates-blog-post-archive-tr-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-post-tr-js": () => import("./../../../src/templates/blog-post.tr.js" /* webpackChunkName: "component---src-templates-blog-post-tr-js" */),
  "component---src-templates-blog-tag-post-archive-js": () => import("./../../../src/templates/blog-tag-post-archive.js" /* webpackChunkName: "component---src-templates-blog-tag-post-archive-js" */),
  "component---src-templates-blog-tag-post-archive-tr-js": () => import("./../../../src/templates/blog-tag-post-archive.tr.js" /* webpackChunkName: "component---src-templates-blog-tag-post-archive-tr-js" */),
  "component---src-templates-case-study-archive-js": () => import("./../../../src/templates/case-study-archive.js" /* webpackChunkName: "component---src-templates-case-study-archive-js" */),
  "component---src-templates-case-study-archive-tr-js": () => import("./../../../src/templates/case-study-archive.tr.js" /* webpackChunkName: "component---src-templates-case-study-archive-tr-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/case-study.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-case-study-tr-js": () => import("./../../../src/templates/case-study.tr.js" /* webpackChunkName: "component---src-templates-case-study-tr-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

